import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Img from "gatsby-image"

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  date,
  helmet,
  featuredImg,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div>
      {featuredImg ? (
        <section className="hero hero-blogpost has-overlay section background--image background--fixed"
          style={{
            backgroundImage: `url(${
              !!featuredImg.childImageSharp ? featuredImg.childImageSharp.fluid.src : null
              })`
          }}
        >
          <div className="container" style={{ flexGrow: 0 }}>
            <div style={{ zIndex: 1 }}>
              <p className="subtitle">{date}</p>
              <h1 class="title">{title}</h1>
              <p>{description}</p>
            </div>
          </div>
        </section >
      ) : (
          <div className="container content">
            <div className="columns">
              <div className="column is-10 is-offset-1">
              </div>
            </div>
          </div>
        )}


      <section className="section">
        {helmet || ''}
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              {
                featuredImg ? (
                  <Img fluid={featuredImg.childImageSharp.fluid} />
                ) : ''
              }
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <h5 className="subtitle">{date}</h5>
              <p><b>{description}</b></p>
              <PostContent content={content} />
              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        featuredImg={post.frontmatter.featuredimage}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
                markdownRemark(id: {eq: $id }) {
                id
      html
              frontmatter {
                date(formatString: "DD. MMMM YYYY", locale: "de")
              title
              description
              tags
        featuredimage {
                childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
              }
            }
          }
        }
      }
    `
